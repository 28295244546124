import React from 'react';

const confirmationDataItems = ({ confirmationData }) => (
    confirmationData.items.map(item => 
      <div className="confirmation-item" key={item.label}>
        <div className="confirmation-item-label">
          {item.label}  
        </div>
        <div className="confirmation-item-value">
          {item.value}
        </div>
      </div>
    )
);

export default confirmationDataItems;