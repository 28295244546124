import React from 'react';
import ClientDataFields from './ClientDataFields/ClientDataFields';
import Loader from '../Loader/Loader';

const clientData = ({ step, isSectionLoading, clientData, handleClientData }) => {
  return (
  <>
    <Loader show={isSectionLoading && step === 3} />
    {!isSectionLoading && step > 2 &&
    <>
      <div className="section your-data-wrapper">
        <div className="your-data">
          <div className="title-section">
            <span>Podaj swoje dane</span>
          </div>
          <div className="content">
            <ClientDataFields
              fields={clientData}
              handleField={handleClientData} />
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </>}
  </>
  )
};

export default clientData;