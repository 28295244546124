import React from 'react';
import StandardInput from '../Field/Input/standardInput';

const barcodeInput = ({ barcodeInput, handleBarcodeInput }) => (
  <>
    <div className="section barcode-data-wrapper">
      <div className="your-data">
        <div className="title-section">
          <span>Wprowadź dane karty</span>
        </div>
        <div className="content">
        <StandardInput 
          key={barcodeInput.name} 
          field={barcodeInput} 
          handleField={handleBarcodeInput} />
        </div>
      </div>
    </div>
    <div className="clearfix"></div>
  </>
);

export default barcodeInput;