import React from 'react';
import appHelper from '../../appHelper';

const agreement = ({ agreementState, handleAgreement }) => (
  <div className="control checkbox declare">
    <input type="checkbox" name="create-an-account" id="declare" checked={agreementState} onChange={handleAgreement} />
    <label htmlFor="declare"><span className="black">Oświadczam, że:</span> {appHelper.AGREEMENT}</label>
  </div>
);

export default agreement;