import React from 'react';
import appHelper from '../../appHelper';

const PaymentResult = ({ resultType }) => {
  let caption;
  let info;

  if (resultType === 'Success') {
    caption = appHelper.SUCCESS_PAYMENT_CAPTION;
    info = appHelper.SUCCESS_PAYMENT_INFO;
  } else {
    caption = appHelper.FAIL_PAYMENT_CAPTION;
    info = appHelper.FAIL_PAYMENT_INFO;
  };

  return (
    caption && 
    info && 
    <div className="payment-result">
      <h1 className={`payment-result-${resultType}`}>{caption}</h1>
      <p className={'payment-result-info'}>{info}</p>
      <form action={process.env.PUBLIC_URL}>
        <button className="button">Powrót</button>
      </form>
    </div>
  );
};

export default PaymentResult;