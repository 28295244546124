const CONFIRMATION_MODEL = {
  productData: [],
  agreement: false,
  clientApi: 'PaymentsLiteEn',
  clientIp: '',
  marketingAgreement: false,
  marketingSmsAgreement : false
};

const PAYMENT_MODEL = {
  clientData: {},
  productData: [],
  paymentData: {},
  agreement: false,
  marketingAgreement: false,
  transactionKind: 0,
  clientApi: 'PaymentsLiteEn',
  clientIp: ''
};

const serializeConfirmationData = (productInputs, agreement, clientIp, agreementMarketing) => {
  CONFIRMATION_MODEL.productData = mapProductInputsToProductData(productInputs);
  CONFIRMATION_MODEL.agreement = agreement;
  CONFIRMATION_MODEL.clientIp = clientIp;
  CONFIRMATION_MODEL.marketingAgreement = agreementMarketing;
  
	if (localStorage.getItem('api') != null && localStorage.getItem('api') === 'android') {
		CONFIRMATION_MODEL.clientApi = 'AndroidLiteEn'
	} else {
		CONFIRMATION_MODEL.clientApi = 'PaymentsLiteEn'
	}	

  return JSON.stringify(CONFIRMATION_MODEL);
};

const serializePaymentData = (clientData, productInputs, payment, agreement, transactionKind, clientIp, agreementMarketing) => {
  clientData.forEach(cd => {
    PAYMENT_MODEL.clientData[cd.name] = cd.value;
  });
  
  PAYMENT_MODEL.productData = mapProductInputsToProductData(productInputs);
  PAYMENT_MODEL.paymentData = payment;
  PAYMENT_MODEL.agreement = agreement;
  PAYMENT_MODEL.clientIp = clientIp;
  PAYMENT_MODEL.transactionKind = transactionKind;
  PAYMENT_MODEL.marketingAgreement = agreementMarketing;
  
	if (localStorage.getItem('api') != null && localStorage.getItem('api') === 'android') {
		PAYMENT_MODEL.clientApi = 'AndroidLiteEn'
	} else {
		PAYMENT_MODEL.clientApi = 'PaymentsLiteEn'
	}	

  return JSON.stringify(PAYMENT_MODEL);
};

const mapProductInputsToProductData = (productInputs) => {
  const productData = [];
  
  productInputs.forEach(input => {
    let product = {
      id: input.additionalData.id,
      key: input.additionalData.key,
      barcodeMask: input.additionalData.barcodeMask,
      name: input.displayName,
      shortName: input.name,
      price: input.value,
    };

    productData.push(product);
  });

  return productData;
};

export {
  serializeConfirmationData,
  serializePaymentData
};