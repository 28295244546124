import React from 'react';

const preventCopy = (e, shouldPrevent) => {
  if (shouldPrevent) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }
};

const standardInput = ({ field, handleField }) => (
  <div className="control text">
    <input 
      type="text" 
      name={field.name} 
      required={field.required} 
      value={field.value} 
      autoComplete={field.autocomplete}
      onChange={event => handleField(event.target)}
      onCopy={e => preventCopy(e, !field.copiable)}
      onDrag={e => preventCopy(e, !field.copiable)}
      onDrop={e => preventCopy(e, !field.copiable)}
      onPaste={e => preventCopy(e, !field.copiable)} />
    <label>{field.display}</label>
    {field.error ? <div className="input-error">{field.error}</div> : null}
  </div>
);

export default standardInput;