/*import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga'
*/
const enable = process.env.REACT_APP_GTM_ENABLE === "true"

const clearECDataLayer = (dataLayer) => {
    if (!enable) { return }
    dataLayer.push({ ecommerce: null });
}

const addECStatClickProduct = (dataLayer, productData, confirmationData) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "view_item",
        ecommerce: {
            items: [
                {
                    item_id: productData[0].id,
                    item_name: "Doładowanie energii",
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Energy",
                    item_category: "",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "en_products",
                    item_list_name: "EN products",
                    item_variant: confirmationData.items.find(data => data.name === 'Provider').value,
                    location_id: "Lite",
                    price: confirmationData.items.find(data => data.name === 'Total').value,
                    quantity: 1
                }]
        }
    });
}

const addECStatProductAddToCart = (dataLayer, productData, confirmationData) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({ ecommerce: null });  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [
                {
                    item_id: productData[0].id,
                    item_name: "Doładowanie energii",
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Energy",
                    item_category: "",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "en_products",
                    item_list_name: "EN products",
                    item_variant: confirmationData.items.find(data => data.name === 'Provider').value,
                    location_id: "Lite",
                    price: confirmationData.items.find(data => data.name === 'Total').value,
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatViewCart = (dataLayer, productData, confirmationData) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "view_cart",
        ecommerce: {
            currency: "PLN",
            value: confirmationData.items.find(data => data.name === 'Total').value, //wartość produktów w koszyku po odliczeniu rabatów
            items: [
                {
                    item_id: productData[0].id,
                    item_name: "Doładowanie energii",
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Energy",
                    item_category: "",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "en_products",
                    item_list_name: "EN products",
                    item_variant: confirmationData.items.find(data => data.name === 'Provider').value,
                    location_id: "Lite",
                    price: confirmationData.items.find(data => data.name === 'Total').value,
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatBeginCheckout = (dataLayer, productData, confirmationData) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            items: [
                {
                    item_id: productData[0].id,
                    item_name: "Doładowanie energii",
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Energy",
                    item_category: "",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "en_products",
                    item_list_name: "EN products",
                    item_variant: confirmationData.items.find(data => data.name === 'Provider').value,
                    location_id: "Lite",
                    price: confirmationData.items.find(data => data.name === 'Total').value,
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatShippingInfo = (dataLayer, productData, confirmationData) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
            currency: "PLN",
            value: confirmationData.items.find(data => data.name === 'Total').value,
            coupon: "",
            shipping_tier: "Email/SMS",
            items: [
                {
                    item_id: productData[0].id,
                    item_name: "Doładowanie energii",
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Energy",
                    item_category: "",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "en_products",
                    item_list_name: "EN products",
                    item_variant: confirmationData.items.find(data => data.name === 'Provider').value,
                    location_id: "Lite",
                    price: confirmationData.items.find(data => data.name === 'Total').value,
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatPaymentInfo = (dataLayer, productData, confirmationData, paymentData) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
            currency: "PLN",
            value: confirmationData.items.find(data => data.name === 'Total').value,
            coupon: "",
            payment_type: paymentData.name,
            items: [
                {
                    item_id: productData[0].id,
                    item_name: "Doładowanie energii",
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Energy",
                    item_category: "",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "en_products",
                    item_list_name: "EN products",
                    item_variant: confirmationData.items.find(data => data.name === 'Provider').value,
                    location_id: "Lite",
                    price: confirmationData.items.find(data => data.name === 'Total').value,
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatBuy = (dataLayer, productData, confirmationData, task) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)
    let commission = confirmationData.items.find(data => data.name === 'Commission')

    dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: task,
            affiliation: "BillBird",
            value: confirmationData.items.find(data => data.name === 'Total').value,
            tax: (commission === undefined ? 0.00 : commission.value),
            shipping: 0.00,
            currency: "PLN",
            coupon: "",
            items: [{
                item_id: productData[0].id,
                    item_name: "Doładowanie energii",
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Energy",
                    item_category: "",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "en_products",
                    item_list_name: "EN products",
                    item_variant: confirmationData.items.find(data => data.name === 'Provider').value,
                    location_id: "Lite",
                    price: confirmationData.items.find(data => data.name === 'Total').value,
                    quantity: 1
            }]
        }
    });
}

export {
    addECStatBuy,
    addECStatProductAddToCart,
    addECStatClickProduct,
    addECStatViewCart,
    addECStatBeginCheckout,
    addECStatShippingInfo,
    addECStatPaymentInfo,
    clearECDataLayer
}