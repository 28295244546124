import React from 'react';

const imageRadio = ({ field, radioSectionName, handleField }) => (
  <div className="control radio">
    <input 
      type="radio" 
      id={field.shortName} 
      name={radioSectionName} 
      value={field.id} 
      onChange={event => handleField(event.target)} />
    <label htmlFor={field.shortName}>
      <img 
        src={`${process.env.PUBLIC_URL}/img/pay/${field.shortName.toLowerCase()}.gif`} 
        onError={e => e.target.src=""}
        alt={field.name}/> 
    </label>    
  </div>
);

export default imageRadio;