import React from 'react';
import Loader from '../Loader/Loader';
import ConfirmationDataItems from './ConfirmationDataItems/ConfirmationDataItems';

const confirmationData = ({ step, isSectionLoading, confirmationData }) => {
  return (
    <>
      <Loader show={isSectionLoading && step === 2} />
      {!isSectionLoading && step > 1 &&
      <>
        <div className="section confirmation-data-wrapper">
          <div className="confirmation-data">
            <div className="title-section">
              <span>Sprawdź poprawność danych</span>
            </div>
            <div className="content">
              <ConfirmationDataItems confirmationData={confirmationData} />
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </>}
    </>
  );
};

export default confirmationData;