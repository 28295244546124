import React from 'react';
import StandardInput from '../../Field/Input/standardInput';

const productDataFields = ({ fields, handleField }) => (
  fields.map(field => <StandardInput 
                        key={field.name} 
                        field={field} 
                        handleField={handleField} />)
);

export default productDataFields;