import React from 'react';
import appHelper from '../../appHelper';

const agreementMarketing = ({ agreementMarketingState, handleAgreementMarketing }) => (
  <div className="control checkbox declareM">
    <input type="checkbox" name="marketing-aggrement" id="declareM" checked={agreementMarketingState} onChange={handleAgreementMarketing} />
    <label htmlFor="declareM"><span className="black">Oświadczam, że:</span> {appHelper.AGREEMENT_MARKETING}</label>
  </div>
);

export default agreementMarketing;