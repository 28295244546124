import React from 'react';
import ProductDataFields from '../ProductData/ProductDataFields/ProductDataFields';
import Agreement from '../Agreement/Agreement';
import Checked from '../Agreement/Checked';
import AgreementMarketing from '../Agreement/AgreementMarketing';
import Loader from '../Loader/Loader';

const productData = ({ step, isSectionLoading, productData, handleProductData, agreementState, 
                      agreementMarketingState, handleAgreement, handleAgreementMarketing, handleCheckAll }) => {
  return (
    <>
      <Loader show={isSectionLoading && step === 1} />
      {!isSectionLoading && step > 0 &&
      <>
        <div className="section product-data-wrapper">
          <div className="product-data">
            <div className="title-section">
              <span>Wpisz kwotę doładowania</span>
            </div>
            <div className="content">
              <ProductDataFields
                fields={productData}
                handleField={handleProductData} />
              <Checked 
                handleCheckAll={handleCheckAll}/>
              <Agreement
                agreementState={agreementState} 
                handleAgreement={handleAgreement}/>
              <AgreementMarketing
                agreementMarketingState={agreementMarketingState}
                handleAgreementMarketing={handleAgreementMarketing} />
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </>}
    </>
  );
};

export default productData;