import React from 'react';
import ClientData from '../../components/ClientData/ClientData';
import ProductData from '../../components/ProductData/ProductData';
import PaymentData from '../../components/PaymentData/PaymentData';
import FormPostErrors from '../../components/Error/FormPostErrors';
import BarcodeData from '../../components/BarcodeInput/BarcodeInput';
import ConfirmationData from '../../components/ConfirmationData/ConfirmationData';

const mainForm = (props) => (
  props.show &&
  (<form className="my-energy-form" onSubmit={props.handleSubmit}>
    <BarcodeData 
      barcodeInput={props.data.barcodeInput}
      handleBarcodeInput={props.handleBarcodeInput} />
    <ProductData
      step={props.step}
      isSectionLoading={props.isSectionLoading.product}
      productData={props.data.productData}
      handleProductData={props.handleProductData}
      agreementState={props.data.agreement}
      agreementMarketingState={props.data.agreementMarketing}
      handleAgreement={props.handleAgreement}
      handleAgreementMarketing={props.handleAgreementMarketing}
      handleCheckAll={props.handleCheckAll}  />
    <ConfirmationData
      step={props.step}
      isSectionLoading={props.isSectionLoading.confirmation}
      confirmationData={props.data.confirmationData} />
    <ClientData
      step={props.step}
      isSectionLoading={props.isSectionLoading.client}
      clientData={props.data.clientData}
      handleClientData={props.handleClientData} />
    <PaymentData
      step={props.step}
      isSectionLoading={props.isSectionLoading.payment}
      paymentData={props.data.paymentData}
      handlePaymentData={props.handlePaymentData} />
    <FormPostErrors 
      formErrors={props.formError} />
    <button className="button" type="submit">{props.step < 4 ? 'Dalej' : 'Płacę'}</button>
  </form>)
);

export default mainForm;