import React from 'react';
import ImageRadio from '../../Field/Radio/ImageRadio';

const paymentDataFields = ({ fields, handleField }) => (
  fields.map(field => <ImageRadio 
                        key={field.shortName} 
                        field={field}
                        radioSectionName="payment"
                        handleField={handleField} />)
);

export default paymentDataFields;