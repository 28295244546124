import React from 'react';
import PaymentDataFields from './PaymentDataFields/PaymentDataFields';
import Loader from '../Loader/Loader';

const paymentData = ({ step, isSectionLoading, paymentData, handlePaymentData }) => {
  return (
    <>
      <Loader show={isSectionLoading && step === 4} />
      {!isSectionLoading && step > 3 &&
      <>
        <div className="section payment-method-wrapper">
          <div className="payment-method">
            <div className="title-section">
              <span>Wybierz sposób płatności</span>
            </div>
            <div className="content">
              <div className="payment">
                <PaymentDataFields
                  fields={paymentData}
                  handleField={handlePaymentData} />            
              </div>
              {paymentData.error ? <div className="input-error">{paymentData.error}</div> : null}
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </>}
    </>
  );
};

export default paymentData;