import React from 'react';
import appHelper from '../../appHelper';

const standardError = ({ error }) => (
  error.show && (
    <div className="error">
      <p className="error-caption">{appHelper.ERR_STANDARD_CAPTION}</p>
      <p className="error-info">{error.message}</p>
    </div>
    )
);

export default standardError;