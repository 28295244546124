import publicIp from "public-ip";

const appHelper = (() => {

  const URL = process.env.REACT_APP_URL;
  const API_URL = `${URL}/api/1.0/paymentslite`;
  const SERVICE_PATH = '/service';
  const CONFIRM_PATH = '/confirm';
  const PAYMENT_PATH = '/pay';
  const SERVICE_NAME = 'Energy';

  const AGREEMENT = 'Wyrażam zgodę na realizację Usługi inicjowania zapłaty za rachunki w trybie natychmiastowym, tj. przed upływem terminu odstąpienia od umowy. Przyjmuję do wiadomości, że wyrażenie niniejszej zgody skutkuje utratą przeze mnie prawa odstąpienia od umowy. Zapoznałem się z Regulaminem i akceptuję zawarte w nim warunki. Przyjmuję do wiadomości, że otrzymanie Regulaminu na trwałym nośniku nastąpi z chwilą przekazania przez Usługodawcę potwierdzenia zawarcia umowy, przed rozpoczęciem świadczenia Usługi.';
  const AGREEMENT_MARKETING = 'wyrażam zgodę na przesyłanie mi przez BillBird S.A. z siedzibą w Krakowie, za pośrednictwem poczty elektronicznej, informacji o najnowszych promocjach, konkursach oraz akcjach dotyczących produktów znajdujących się w naszej ofercie, Jednocześnie informujemy, że w każdej chwili możesz cofnąć udzieloną zgodę poprzez odpowiedź na otrzymaną od nas wiadomość.';
  const SUCCESS_PAYMENT_CAPTION = 'Dziękujemy';
  const SUCCESS_PAYMENT_INFO = 'Potwierdzenie transakcji zostało przesłane na Twój adres mailowy';
  const FAIL_PAYMENT_CAPTION = 'Ups...';
  const FAIL_PAYMENT_INFO = 'Nie udało się zrealizować transakcji';
  
  const ERR_STANDARD_CAPTION = 'Ups... coś poszło nie tak';
  const ERR_PRODUCT = 'Musisz wybrać nominał';
  const ERR_PAYMENT = 'Musisz wybrać formę płatności';
  const ERR_AGREEMENT = 'Musisz zaakceptować regulamin';
  const ERR_FORM = 'Sprawdź poprawność wypełnienia pól formularza';

  const API_RESPONSE_STATUS = {
    STATUS_OK: 'OK',
    STATUS_FAIL: 'FAIL'
  };

  const getServiceUrl = (barcode) => `${API_URL}${SERVICE_PATH}?name=${SERVICE_NAME}&barcode=${barcode}`;

  const getConfirmationUrl = () => `${API_URL}${CONFIRM_PATH}`;
  
  const getPaymentUrl = () => `${API_URL}${PAYMENT_PATH}`;
  
  const getRedirectUrl = (redirectPath, clientEmail) => `${URL}/${redirectPath}&email=${clientEmail}`;
  
  const getAcceptedUrlPath = () => {
    let path = window.location.search.substr(1);
    if (path === 'Success') {
      return { accepted: true, path }
    } else if (path === 'Fail') {
      return { accepted: true, path };
    };

    return { accepted: false }
  };

  const getClientIp = async () => await publicIp.v4({
    fallbackUrls: ["https://ifconfig.co/ip"]
  })

  return {
    URL,
    API_URL,
    SERVICE_PATH,
    SERVICE_NAME,
    AGREEMENT,
    AGREEMENT_MARKETING,
    SUCCESS_PAYMENT_CAPTION,
    SUCCESS_PAYMENT_INFO,
    FAIL_PAYMENT_CAPTION,
    FAIL_PAYMENT_INFO,
    ERR_STANDARD_CAPTION,
    ERR_PRODUCT,
    ERR_PAYMENT,
    ERR_AGREEMENT,
    ERR_FORM,
    API_RESPONSE_STATUS,
    getServiceUrl,
    getConfirmationUrl,
    getPaymentUrl,
    getRedirectUrl,
    getAcceptedUrlPath,
    getClientIp
  };

})();



export default appHelper;