import React, { useState, useEffect } from 'react';
import Title from './components/Title/Title';
import MainForm from './containers/MainForm/MainForm';
import StandardError from './components/Error/StandardError';
import Loader from './components/Loader/Loader';
import PaymentResult from './components/PaymentResult/PaymentResult';

import restClient from './logic/restClient';
import { addECStatBeginCheckout, addECStatBuy, addECStatClickProduct, addECStatPaymentInfo, addECStatProductAddToCart, addECStatShippingInfo, addECStatViewCart } from './logic/eCommerceClient';
import { CLIENT_INPUTS, BARCODE_INPUT, createEnergyInputs, validateAllInputs, validateObjectNotEmpty } from './logic/inputsHandler';
import { serializeConfirmationData, serializePaymentData } from './logic/jsonSerializer';
import appHelper from './appHelper';

const App = () => {
  const [step, setStep] = useState(0);
  const [barcodeInput, setBarcodeInput] = useState(BARCODE_INPUT);
  const [clientData, setClientData] = useState([...CLIENT_INPUTS]);
  const [productData, setProductData] = useState([]);
  const [confirmationData, setConfirmationData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [payment, setPayment] = useState({});
  const [agreement, setAgreement] = useState(false);
  const [agreementMarketing, setAgreementMarketing] = useState(false);
  const [checked, setChecked] = useState(false);
  const [transactionKind, setTransactionKind] = useState(0);
  const [clientIp, setClientIp] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isSectionLoading, setIsSectionLoading] = useState({ product: false, confirmation: false, client: false, payment: false });
  const [standardError, setStandardError] = useState({ show: false, message: '' });
  const [formPostErrors, setFormPostErrors] = useState([]);

  useEffect(() => {
    localStorage.setItem('isClientDataFilled', false);
  }, [])

  const api = new URLSearchParams(window.location.search).get('api')
  const savedData = localStorage.getItem('clientData') !== null ? JSON.parse(localStorage.getItem('clientData')) : undefined

  if (savedData !== undefined && localStorage.getItem('isClientDataFilled') !== null && localStorage.getItem('isClientDataFilled') === 'false') {
    localStorage.setItem('isClientDataFilled', 'true')
    barcodeInput.value = savedData.cardNo
    clientData.at(0).value = savedData.email
  }

  if (localStorage.getItem('api') === null && api !== null) {
    localStorage.setItem('api', api)
  }

  const loadProductData = (barcode) => {
    (async () => {
      setStandardError({ show: false, message: '' });
      setIsSectionLoading({ ...isSectionLoading, product: true });

      try {
        const response = await restClient.get(appHelper.getServiceUrl(barcode));
        if (response.data.Status === appHelper.API_RESPONSE_STATUS.STATUS_FAIL) {
          throw new Error(response.data.Message);
        };

        let data = JSON.parse(response.data.Data);
        let productInputs = createEnergyInputs(data.productData);

        setProductData(productInputs);
        setPaymentData(data.paymentData);

        appHelper.getClientIp().then((promise) => {
          setClientIp(promise);
        });

      } catch (error) {
        console.error(error);
        setStandardError({ show: true, message: error.message });
        setStep(step => step - 1);
      };

      setIsSectionLoading({ ...isSectionLoading, product: false });
    })();
  };

  const loadConfirmationData = () => {
    (async () => {
      setStandardError({ show: false, message: '' });
      setIsSectionLoading({ ...isSectionLoading, confirmation: true });

      try {
        const params = {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        };

        const jsonData = serializeConfirmationData(productData, agreement, clientIp, agreementMarketing);
        const response = await restClient.post(appHelper.getConfirmationUrl(), jsonData, params);
        if (response.data.Status === appHelper.API_RESPONSE_STATUS.STATUS_FAIL) {
          throw new Error(response.data.Message);
        };

        let data = JSON.parse(response.data.Data);
        setConfirmationData(data);
        setTransactionKind(data.transactionKind);

        addECStatClickProduct(window.dataLayer, productData, data)

        appHelper.getClientIp().then((promise) => {
          setClientIp(promise);
        });
      } catch (error) {
        console.error(error);
        setStandardError({ show: true, message: error.message });
        setStep(step => step - 1);
      };

      setIsSectionLoading({ ...isSectionLoading, confirmation: false });
    })();
  };

  const pay = () => {
    (async () => {
      setStandardError({ show: false, message: '' });
      setIsLoading(true);

      try {
        const params = {
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        };
        const jsonData = serializePaymentData(clientData, productData, payment, agreement, transactionKind, clientIp, agreementMarketing);
        const response = await restClient.post(appHelper.getPaymentUrl(), jsonData, params);
        if (response.data.Status === appHelper.API_RESPONSE_STATUS.STATUS_FAIL) {
          throw new Error(response.data.Message);
        };

        let data = response.data.Data;
        addECStatBuy(window.dataLayer, productData, confirmationData, data.task);
        let redirectUrl = appHelper.getRedirectUrl(data.url, JSON.parse(jsonData).clientData.email);
        window.location.replace(redirectUrl);
      } catch (error) {
        console.error(error);
        setStandardError({ show: true, message: error.message });
        setIsLoading(false);
      };
    })();
  };

  const handleBarcodeInput = (target) => {
    if (step > 0) {
      setStep(0);
    };

    let barcodeCharArray = target.value.split('');
    let currentChar = barcodeCharArray.pop();
    if (currentChar && (currentChar === ' ' || isNaN(currentChar))) {
      return;
    };

    let newBarcodeInput = { ...barcodeInput };
    newBarcodeInput.value = target.value;
    setBarcodeInput(newBarcodeInput);
  };

  const handleProductData = (target) => {
    if (step > 1) {
      setStep(1);
    };

    let productCharArray = target.value.split('');
    let currentChar = productCharArray.pop();
    if (productCharArray.length === 0 && (currentChar === ',' || currentChar === '.')) {
      productCharArray.push('0');
      productCharArray.push('.');
    } else if ((currentChar === ',' || currentChar === '.') && !productCharArray.includes('.')) {
      productCharArray.push('.');
    } else if (!isNaN(+currentChar) && (!productCharArray.includes('.') || productCharArray.lastIndexOf('.') >= productCharArray.length - 2)) {
      productCharArray.push(currentChar);
    }

    target.value = productCharArray.join('');

    let newProductData = [...productData];
    newProductData.find(data => data.name === target.name).value = target.value;
    setProductData(newProductData);
  };

  const handleClientData = (target) => {
    let newClientData = [...clientData];
    newClientData.find(data => data.name === target.name).value = target.value;
    setClientData(newClientData);
  };

  const handlePaymentData = (target) => {
    let payment = paymentData.find(p => p.id === target.value);
    setPayment({ ...payment, value: target.value });
    addECStatPaymentInfo(window.dataLayer, productData, confirmationData, payment);
  }

  const handleAgreement = (target) => {
    setAgreement(!agreement);
  };

  const handleCheckAll = e => {
    if (checked === false) {
      setAgreement(true)
      setAgreementMarketing(true)
    } else {
      setAgreement(false)
      setAgreementMarketing(false)
    }
    setChecked((e) => !e)
  };

  const handleAgreementMarketing = () => {
    setAgreementMarketing(!agreementMarketing);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let newStep = step < 5 ? step + 1 : step;
    setStep(newStep);

    switch (newStep) {
      case 1:
        // PRODUCT SECTION
        loadProductData(barcodeInput.value);
        break;

      case 2:
        // CONFIRMATION SECTION
        let productDataToSend = [...productData];
        let isProductDataValid = validateAllInputs(productDataToSend) && agreement;

        setFormPostErrors([]);
        if (!agreement) {
          setFormPostErrors(errors => [...errors, appHelper.ERR_AGREEMENT]);
        };

        setProductData(productDataToSend);
        if (isProductDataValid) {
          loadConfirmationData();
        } else {
          setStep(step => step - 1);
        };
        break;

      case 3:
        // CLIENT SECTION
        addECStatProductAddToCart(window.dataLayer, productData, confirmationData);
        setIsSectionLoading({ ...isSectionLoading, client: true });
        setTimeout(() => {
          setIsSectionLoading({ ...isSectionLoading, client: false });
          addECStatViewCart(window.dataLayer, productData, confirmationData);
        }, 200);

        break;

      case 4:
        // PAYMENT SECTION
        let clientDataToSend = [...clientData];
        let isClientDataValid = validateAllInputs(clientDataToSend);
        addECStatBeginCheckout(window.dataLayer, productData, confirmationData);
        setClientData(clientDataToSend);
        if (isClientDataValid) {
          setIsSectionLoading({ ...isSectionLoading, payment: true });
          setTimeout(() => {
            setIsSectionLoading({ ...isSectionLoading, payment: false });
            addECStatShippingInfo(window.dataLayer, productData, confirmationData)
          }, 200);
        } else {
          setStep(step => step - 1);
        }
        break;

      case 5:
        // PAY
        let valid = validateForm();
        if (valid) {
          localStorage.setItem('clientData', `{"cardNo":"${barcodeInput.value}","email":"${clientData.at(0).value}"}`)
          pay();
        };
        break;

      default:
        break;
    };
  };

  const validateForm = () => {
    let clientDataToSend = [...clientData];
    let productDataToSend = [...productData];
    let paymentDataToSend = [...paymentData];

    let isClientDataValid = validateAllInputs(clientDataToSend);
    let isProductDataValid = validateAllInputs(productDataToSend);
    paymentDataToSend.error = validateObjectNotEmpty(payment, appHelper.ERR_PAYMENT);

    setFormPostErrors([]);
    if (!agreement) {
      setFormPostErrors(errors => [...errors, appHelper.ERR_AGREEMENT]);
    };

    let isValid = isClientDataValid && isProductDataValid && !paymentDataToSend.error && agreement;

    if (!isValid) {
      setFormPostErrors(errors => [...errors, appHelper.ERR_FORM]);
    };

    setClientData(clientDataToSend);
    setProductData(productDataToSend);
    setPaymentData(paymentDataToSend);

    return isValid;
  };

  return (
    <div className="my-energy">
      {
        appHelper.getAcceptedUrlPath().accepted ?

          <PaymentResult resultType={appHelper.getAcceptedUrlPath().path} />
          :
          <>
            <Title />
            <StandardError error={standardError} />
            <Loader show={isLoading} />
            <MainForm
              show={!isLoading}
              isSectionLoading={isSectionLoading}
              step={step}
              data={{ barcodeInput, clientData, productData, confirmationData, paymentData, agreement, agreementMarketing }}
              formError={formPostErrors}
              handleSubmit={handleSubmit}
              handleBarcodeInput={handleBarcodeInput}
              handleClientData={handleClientData}
              handleProductData={handleProductData}
              handlePaymentData={handlePaymentData}
              handleAgreement={handleAgreement}
              handleAgreementMarketing={handleAgreementMarketing}
              handleCheckAll={handleCheckAll}
              savedData={savedData} />
          </>
      }
    </div>
  );
};

export default App;
