const CLIENT_INPUTS = [];
let BARCODE_INPUT = null;

const INPUT_RELATION_TYPE = {
  MASTER: 'master',
  SLAVE: 'slave'
};

const AUTOCOMPLETE = {
  ON: 'on',
  OFF: 'none'
};

function createInput(name, display, validate, relation = null, required = true, copiable = true, autocomplete = AUTOCOMPLETE.ON, value = '', additionalData = {}) {
  return {
    name,
    display,
    validate,
    relation,
    required,
    copiable,
    autocomplete,
    value,
    additionalData,
    error: null
  };
};

function validateEmpty(input) {
  return !input || input.value.length === 0 ? `Pole ${input.display} nie może być puste` : null;
};

function validateNumber(input) {
  return isNaN(input.value) ? `Pole ${input.display} może zawierać wyłącznie cyfry` : null;
}

function strToNum(str) {
  return +str;
}

// function validateMaxLength(input, length) {
//   return input.value.length > length ? `Maksymalnie ${length} znaków` : null;
// };

function validateRegexp(input, regexp, optionalMessage) {
  return !input.value.match(regexp) ? `Błędny format pola ${input.display} ${optionalMessage || ''}` : null;
};

function validateMatch(slaveInput, masterInput) {
  if (!masterInput) {
    return null;
  };
  
  return slaveInput.value !== masterInput.value ? `Pole ${slaveInput.display} nie zgadza się z polem ${masterInput.display}` : null;
};

function twoTariffValidator(slaveInput, masterInput) {
  if (!masterInput) {
    return null;
  };

  let tariffOneValue = strToNum(masterInput.value.replace(',', '.'));
  let tariffTwoValue = strToNum(slaveInput.value.replace(',', '.'));

  let error = isNaN(tariffOneValue) || isNaN(tariffTwoValue) ? 'Niepoprawna kwota' : null;

  if (!error) {
    error = tariffOneValue > 600 || 
            tariffTwoValue > 600 ||
            tariffOneValue < 0 ||
            tariffTwoValue < 0 ? 'Kwota musi zawierać się w przedziale 0-600' : null;
  }

  if (!error) {
    error = tariffOneValue === 0 && tariffTwoValue === 0 ? 'Wpisz wartość dla jednej lub obu taryf' : null;
  };

  return error;
};

function validateAllInputs(inputs) {
  let hasErrors = false;
  let relations = {};

  inputs.forEach(input => {
    let error = null;

    if (input.relation) {
      if (input.relation.type === INPUT_RELATION_TYPE.MASTER) {
        relations[input.name] = input;
      } else if (input.relation.type === INPUT_RELATION_TYPE.SLAVE) {
        error = input.relation.validateRelation(input, relations[input.relation.master]);
      }
    };

    if (!error) {
      error = input.validate(input);
    }      
    
    input.error = error || null;
    
    if (error) {
      hasErrors = true;
    }
  });

  return !hasErrors;
};

function validateObjectNotEmpty(object, message) {
  return Object.getOwnPropertyNames(object).length === 0 ? message : null;
};

/* CREATING INPUTS */

function emailValidator(input) {
  let emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validateEmpty(input) || validateRegexp(input, emailRegexp);
};

function barcodeValidator(input) {
  return validateEmpty(input) || validateNumber(input);
};

function oneTariffValidator(input) {
  let tariffValue = strToNum(input.value.replace(',', '.'));
  
  let error = validateEmpty(input) || isNaN(tariffValue) ? 'Niepoprawna kwota' : null;

  if (!error) {
    error = tariffValue <= 0 || tariffValue > 600 ? 'Kwota musi zawierać się w przedziale 0-600' : null;
  }

  return error;
};

function createEnergyInputs(products) {
  let inputs = [];
  
  if (products.length === 1) {
    let productData = products[0];
    inputs.push(createInput(productData.shortName, productData.name, oneTariffValidator, null, true, true, AUTOCOMPLETE.OFF, '', { ...productData }));
  } else if (products.length === 2) {
    let tariffOne = products[0];
    let tariffTwo = products[1];
    inputs.push(createInput(tariffOne.shortName, tariffOne.name, validateEmpty, { type: INPUT_RELATION_TYPE.MASTER }, true, true, AUTOCOMPLETE.OFF, '', { ...tariffOne }));
    inputs.push(createInput(tariffTwo.shortName, tariffTwo.name, validateEmpty, { type: INPUT_RELATION_TYPE.SLAVE, master: tariffOne.shortName, validateRelation: twoTariffValidator }, true, true, AUTOCOMPLETE.OFF, '', { ...tariffTwo }));
  };

  return inputs;
};

CLIENT_INPUTS.push(createInput('email', 'Email', emailValidator, { type: INPUT_RELATION_TYPE.MASTER }, true, false, AUTOCOMPLETE.ON));
CLIENT_INPUTS.push(createInput('email-repeat', 'Powtórz email', emailValidator, { type: INPUT_RELATION_TYPE.SLAVE, master: 'email', validateRelation: validateMatch }, true, false, AUTOCOMPLETE.OFF));
BARCODE_INPUT = createInput('barcode', 'Numer karty', barcodeValidator);

export {
  CLIENT_INPUTS,
  BARCODE_INPUT,
  INPUT_RELATION_TYPE,
  validateAllInputs,
  validateObjectNotEmpty,
  createEnergyInputs
};